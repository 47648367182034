<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="$t('pages.employee.inviteTit')"
        :mask-closable="false"
        width="567"
        @on-visible-change="handleVisibleChange">
        <div class="section">
            <div class="link line-overflow">{{ text }}</div>
            <div class="btn pointer" @click="handleCopy">{{ $t('pages.employee.click') }}</div>
        </div>
        <div class="tip">{{ $t('pages.employee.effectiveTime') }}</div>
        <div slot="footer"></div>
    </Modal>  
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            text: {
                default: ''
            }
        },
        data () {
            return {
                modal: false,
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
            }
        },
        methods: {
            cancel() {
                this.linkText = ''
                this.$emit('cancel')
            },
            handleCopy() {
                this.$copyText(this.text).then(() => {
                    this.$Message.success(this.$t('common.copySuccess'))
                    this.cancel()
                }, () => {
                    this.$Message.error(this.$t('common.copyFailed'))
                })
            },
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
@import "~less/color";

.section {
    margin-bottom: 15px;
    height: 40px;
    .link {
        display: inline-block;
        margin-right: 5px;
        width: 307px;
        height: 100%;
        background: #ececec;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #afb4ba;
        line-height: 40px;
    }
    .btn {
        display: inline-block;
        width: 115px;
        height: 100%;
        background: @primary-color;
        border-radius: 4px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 40px;
        color: #fff;
        vertical-align: top;
    }
    .tip {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
    }
}
</style>