<template>
    <div>
        <div class="header">
            <h1 class="title">{{ $t('navigation.employee') }}
                ({{ total }})
            </h1>
        </div>
        <div class="search-bar">

            <span>{{ $t('pages.employee.name') }}：</span>
            <Input v-model="query" class="margin-right" clearable :placeholder="$t('pages.employee.inputName')" style="width: 140px;" @keyup.native.enter="searchNew" @on-clear="searchNew" ></Input>

            <span>{{ $t('pages.employee.role') }}：</span>
            <Select v-model="role" class="margin-right" clearable style="width:140px" @on-clear="searchNew">
                <Option v-for="(item, index) in roleList" :value="item.rowid" :key="index">{{ item.name }}</Option>
            </Select>
            <span>{{ $t('navigation.structure') }}：</span>
            <Select ref="department" v-model="formValidate.department_id" clearable style="width:140px" @on-change="handleClear">
                <Option style="display: none;" :value="departmentInfo.department_id">{{ departmentInfo.title }}</Option>
                <Tree :data="data" @on-select-change="handleTree($event, 'department')" ></Tree>
            </Select>

            <Button class="float-right" type="default" @click="reset">{{ $t('common.reset') }}</Button>
            <Button class="float-right margin-right" type="primary" @click="searchNew">{{ $t('common.search') }}</Button>
        </div>

        <div class="main-content">
            <Button class="add" type="primary" icon="ios-add" @click="handleAdd">{{ $t('pages.employee.inviteUser') }}</Button>
            <Table class="table" border :columns="columns" :data="list">
                <template slot-scope="{ row }" slot="avatar">
                    <img class="avatar" :src="`/cym/${row.avatar_uri}?${randomWord}`" width="42" height="42">
                </template>
                <template  v-if="row.super_role_flag === 0" slot-scope="{ row }" slot="action">
                    <span class="action-setting margin-right" @click="handleEdit(row)">{{ $t('common.setting') }}</span>
                    <span class="action-remove" @click="handleRemove(row)">{{ $t('pages.employee.remove') }}</span>
                </template>
            </Table>
            <Spin size="large" fix v-if="loading"></Spin>
            <Page
                class="page"
                :total="total"
                :current="current"
                :page-size="pageSize"
                show-elevator
                @on-change="nextPage" />
        </div>
        <edit-modal
            :show="editModalShow"
            :type="editType"
            :user_id="user_id"
            @confirm="handleEditConfirm"
            @cancel="handleEditCancel"
        ></edit-modal>
    </div>  
</template>
<script>
    import editModal from './components/edit'
    import { tree } from '@/mixins/index'
    import util from '@/libs/util'

    export default {
        name: "",
        mixins: [tree],
        components: {
            editModal
        },
        props: [],
        data () {
            return {
                total: 0,
                query: '',
                role: '',
                current: 1,
                pageSize: 20,
                loading: false,
                roleList: [],
                list: [],
                editModalShow: false,
                departmentInfo: {
                    department_id: '',
                    title: ''
                },
                nodeData: {},
                data: [],
                formValidate: {
                    department_id: ''
                },
                editType: 'add',
                user_id: null,
                randomWord: util.randomWord(20)
            }
        },
        computed: {
            tree_data() {
                return [this.$store.state.department_tree]
            },
            columns() {
                return [
                    {
                        title: this.$t('pages.employee.avatar'),
                        slot: "avatar",
                        align: "center",
                        minWidth: 150
                    },{
                        title: this.$t('pages.employee.name'),
                        key: "name",
                        align: "center",
                        minWidth: 150
                    },{
                        title: this.$t('pages.screen.phoneNumber'),
                        key: "mobile",
                        align: "center",
                        minWidth: 150
                    },{
                        title: this.$t('pages.employee.role'),
                        key: 'role_name',
                        align: 'center',
                        minWidth: 100
                    },{
                        title: this.$t('pages.employee.belongTo'),
                        key: "department_name",
                        align: "center",
                        minWidth: 200
                    },{
                        title: this.$t('pages.employee.inviter'),
                        key: "inviter_name",
                        align: "center",
                        minWidth: 150
                    },
                    // {
                    //     title: "加入时间",
                    //     key: "join_time",
                    //     align: "center",
                    //     minWidth: 200
                    // },
                    {
                        title: this.$t('common.action'),
                        slot: "action",
                        align: "center",
                        minWidth: 150
                    }
                ]
            }
        },
        methods: {
            searchNew() {
                if (this.loading) return
                this.getData()
            },
            reset() {
                this.query = ''
                this.role = ''
                this.formValidate.department_id = ''
            },
            handleAdd() {
                this.editModalShow = true
                this.editType = 'add'
            },
            handleEdit(row) {
                this.editModalShow = true
                this.editType = 'edit'
                this.user_id = row.user_id
            },
            handleEditConfirm() {
                this.handleEditCancel()
                this.getData()
            },
            handleEditCancel() {
                this.editModalShow = false
            },
            handleRemove(row) {
                this.$Modal.confirm({
                    title: this.$t('pages.screen.tipTit'),
                    content: `${this.$t('pages.employee.removeTip')}${row.name}${this.$t('pages.employee.removeTip2')}`,
                    loading: true,
                    closable: true,
                    onOk: () => {
                        this.$api.delete(`organizations/current/users/${row.user_id}`).then(() => {
                            this.$Modal.remove()
                            this.$Message.success(this.$t('common.success'))
                            this.getData()
                        })
                    }
                });
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                    query: this.query,
                    role_id: this.role,
                    department_id: this.formValidate.department_id,
                    start: (this.current -1) *  this.pageSize,
                    count: this.pageSize
                }
                this.loading = true
                this.$api.get('organizations/current/users/search', { params }).then(({ data, page, total_count }) => {
                    if (page === this.current) {
                        this.list = data
                        this.total = total_count
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            getRoleList() {
                this.$api.get('organizations/current/roles/list').then(({ data }) => {
                    this.roleList = data
                })
            },
            handleClear(e) {
                //清空数据时清除树的选中状态
                if (!e) {
                    this.data = this.handleRecursive(this.tree_data, null)
                    this.searchNew()
                }
            }
        },
        created() {
            this.data = this.handleRecursive(this.tree_data, this.formValidate.department_id)
            this.departmentInfo = {
                department_id: this.nodeData.department_id || '',
                title: this.nodeData.title || ''
            }
            this.department_id = this.nodeData.department_id || ''
        },
        mounted() {
            this.getRoleList()
            this.getData()
        }
    }
</script>
<style scoped lang="less">
@import "~less/color";
.search-bar {
    padding: 22px 24px 22px 46px;
    background: @background-color;
}
.main-content {
    position: relative;
    padding: 105px 24px 0;
    .add {
        position: absolute;
        top: 22px;
        left: 46px;
    }
    .table {
        .avatar {
            border-radius: 50%;
        }
    }
    .page {
        margin-top: 20px;
    }
    .action-setting, .action-remove {
        display: inline-block;
        color: @primary-color;
        &:hover {
            cursor: pointer;
        }
    }
    .action-setting {
        position: relative;
        padding-right: 10px;
        &::before {
            content: '\200B';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 1px;
            height: 15px;
            background: #5a6875;
        }
    }
}
</style>