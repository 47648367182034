<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="type === 'add' ? $t('pages.employee.inviteUser') : $t('pages.employee.setUser')"
        :mask-closable="false"
        width="387">
        <Form class="formValidate" ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
            <FormItem v-if="type === 'edit'" :label="$t('pages.employee.userName')" prop="name">
                <p>{{ formValidate.name }}</p>
            </FormItem>
            <FormItem v-if="type === 'edit'" :label="$t('pages.employee.noteName')" prop="alias">
                <Input v-model="formValidate.alias"></Input>
            </FormItem>
            <FormItem :label="$t('navigation.structure')" prop="department_id">
                <Select ref="department" v-model="formValidate.department_id" style="width:200px" transfer>
                    <Option style="display: none;" :value="departmentInfo.department_id">{{ departmentInfo.title }}</Option>
                    <Tree :data="data" @on-select-change="handleTree($event, 'department')" ></Tree>
                </Select>
            </FormItem>
            <FormItem :label="$t('pages.employee.userRole')" prop="roles">
                <CheckboxGroup v-model="formValidate.roles">
                    <Checkbox v-for="item in roleList" :key="item.rowid" :label="item.rowid">{{ item.name }}</Checkbox>
                </CheckboxGroup>
            </FormItem>
        </Form>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit"><span v-if="type==='edit'">{{ $t('common.confirm') }}</span><span v-else>{{ $t('pages.employee.generateLink') }}</span></Button>
        </div>
        <invite-modal
            :show="inviteModalShow"
            :text="text"
            @cancel="handleInviteModalCancel"
        ></invite-modal>
    </Modal>  
</template>
<script>
    import inviteModal from './inviteModal'

    export default {
        name: "",
        mixins: [],
        components: {
            inviteModal
        },
        props: {
            show: {
                default: false
            },
            type: {
                default: 'add'
            },
            user_id: {
                default: null
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                formValidate: {
                    alias: '',
                    mobile: '',
                    roles: [],
                    name: '',
                    department_id: null
                },
                departmentInfo: {
                    department_id: '',
                    title: ''
                },
                nodeData: {},
                data: [],
                roleList: [],
                inviteModalShow: false,
                text: ''
            }
        },
        computed: {
            tree_data() {
                return [this.$store.state.department_tree]
            },
            ruleValidate() {
                return {
                    // mobile: [
                    //     { required: true, type: 'string', validator: (rule, value, callback) => {
                    //         var regex = /^1[3456789]\d{9}$/
                    //         if (!regex.test(value)) {
                    //             callback(new Error('请输入手机号码'))
                    //         } else {
                    //             callback()
                    //         }
                    //     }, trigger: 'blur' }
                    // ],
                    roles: [
                        { required: true, type: 'array', min: 1, message: this.$t('common.input'), trigger: 'change' }
                    ],
                    department_id: [
                        {
                            required: true, type: 'number', message: this.$t('common.input'), trigger: 'blur'
                        }
                    ]
                }
            }
        },
        watch: {
            async show(val) {
                this.modal = val
                if (val) {
                    this.formValidate.department_id = this.nodeData.department_id || ''
                    if (this.type === 'edit') {
                        await this.$api.get(`organizations/current/users/${this.user_id}`).then(({ data }) => {
                            this.formValidate = {
                                alias: data.alias,
                                name: data.name,
                                department_id: data.department_id,
                                roles: data.role_id_list
                            }
                        })
                    }
                    if (this.type === 'add') {
                        this.formValidate.department_id = this.tree_data[0].department_id
                    }
                    this.data = this.handleRecursive(this.tree_data, this.formValidate.department_id)
                    this.departmentInfo = {
                        department_id: this.nodeData.department_id || '',
                        title: this.nodeData.title || ''
                    }
                    let params = {
                        include_super_role: false
                    }
                    this.$api.get('organizations/current/roles/search', { params }).then(({ data }) => {
                        this.roleList = data
                    })
                }
            },
            modal(val) {
                if (!val) {
                    this.cancel()
                }
            }
        },
        methods: {
            cancel() {
                this.formValidate = {
                    alias: '',
                    mobile: '',
                    roles: [],
                    name: '',
                    department_id: null
                }
                this.departmentInfo =  {
                    department_id: '',
                    title: ''
                }
                this.nodeData = {}
                this.$emit('cancel')
                this.$refs['formValidate'].resetFields();
            },
            submit() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if (this.type === 'add') {
                            let params = {
                                department_id: this.formValidate.department_id,
                                role_list: JSON.stringify(this.formValidate.roles)

                            }
                            this.$api.get('organizations/current/users/invite_user_code', { params }).then(({ url }) => {
                                this.text = `${location.origin}/#/invite_user${url}`
                                this.inviteModalShow = true
                                this.$emit('confirm')
                                this.cancel()
                                this.$Message.success(this.$t('message.success'));
                            }).finally(() => {
                                this.loading = false
                            })
                        } else {
                            let params = {
                                alias: this.formValidate.alias,
                                department_id: this.formValidate.department_id,
                                role_list: this.formValidate.roles
                            }
                            this.$api.put(`organizations/current/users/${this.user_id}`, params).then(() => {
                                this.$emit('confirm')
                                this.cancel()
                                this.$Message.success(this.$t('message.success'));
                            }).finally(() => {
                                this.loading = false
                            })
                        }
                    }
                })
            },
            handleTree(e, name) {
                if (name === 'department') {
                    if (e.length === 0) {
                        this.departmentInfo = {
                            title: '',
                            department_id: ''
                        }
                    } else {
                        this.departmentInfo = {
                            department_id: e[0].department_id,
                            title: e[0].title
                        }
                        this.formValidate.department_id = e[0].department_id
                        this.$refs['department'].hideMenu()
                    }
                }
            },
            handleRecursive(array, key) {
                let arr = []
                for (var i = 0; i < array.length; i++) {
                    let obj = {}

                    //获取相应key节点数据
                    if (key === array[i].department_id) {
                        this.nodeData = {
                            department_id: array[i].department_id,
                            title: array[i].name
                        }
                        obj.selected = true
                    }
                    obj.title = array[i].name
                    obj.expand = true
                    for (let k in array[i]) {
                        if (Object.prototype.hasOwnProperty.call(array[i], k)) {
                            obj[k] = array[i][k].constructor !== Array ? array[i][k] : this.handleRecursive(array[i][k], key)
                        }
                    }
                    arr.push(obj)
                }
                return arr
            },
            handleInviteModalCancel() {
                this.inviteModalShow = false
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
.formValidate {
    width: 80%;
}
</style>
